import styled from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';

export const Wrapper = styled.div`
  position: relative;
`;

export const Line = styled.div`
  background: ${colors.P100};
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
  text-align: center;
  width: 3px;
`;

export const TabContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  margin-bottom: 3rem;
  border: 2px solid ${colors.P100};
  border-radius: 8px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    background: ${colors.P100};
    left: 0;
    right: 0;
    bottom: 32px;
    height: 3px;
  }

  ${mq.gtmd`
    justify-content: space-between;
    border: 0;
    border-radius: 0;
    margin-bottom: 4rem;
  `}
`;

export const BottomTabContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  margin-top: 3rem;

  > div {
    border: 2px solid ${colors.P100};
    border-radius: 8px;
  }

  ${mq.gtmd`
    margin-top: 4rem;
  `}
`;

export const Heading = styled.div`
  position: absolute;
  z-index: 2;
  border: 0 !important;
  top: -1rem;
`;
