import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useIntl } from '@utils/localize';

import * as styled from './styles';

import { BlobOne, RoughOne } from '@components/vector';
import { Heading } from '@components/type';
import StoryPreview from '@components/storyPreview/Text';
import Image from '@components/Image';

function TimelineItem({
  heading,
  image,
  description,
  hour,
  meridian,
  inverted,
  quote,
  relatedStory,
  colorTheme,
  tilted,
}) {
  const intl = useIntl();

  const getCard = () => {
    if (relatedStory) {
      return (
        <styled.RelatedStory colorTheme={colorTheme}>
          <Heading levels={[400, 300]}>{intl.formatMessage({ id: 'relatedStory' })}</Heading>
          <StoryPreview {...relatedStory} type="card" />
          <RoughOne color={styled.getThemeStyles(colorTheme).background} />
        </styled.RelatedStory>
      );
    }

    if (quote) {
      return <styled.Quote colorTheme={colorTheme}>&ldquo;{quote}&rdquo;</styled.Quote>;
    }

    if (get(image, 'asset.fluid')) {
      return <Image fluid={image.asset.fluid} edge={2} ratio={4 / 6} />;
    }
  };
  return (
    <styled.Wrapper inverted={inverted}>
      <styled.Date colorTheme={colorTheme}>
        <styled.Backdrop>
          <BlobOne color={styled.getThemeStyles(colorTheme).background} />
        </styled.Backdrop>
        <Heading levels={[300, 200]}>{hour}</Heading>
        <styled.Time>{meridian}</styled.Time>
      </styled.Date>
      <styled.AssetWrapper inverted={inverted} tilted={tilted}>
        {getCard()}
      </styled.AssetWrapper>
      <styled.Info>
        <Heading levels={[300, 200]}>{heading}</Heading>
        <styled.Body>{description}</styled.Body>
      </styled.Info>
    </styled.Wrapper>
  );
}

TimelineItem.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string,
  hour: PropTypes.string.isRequired,
  meridian: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  tilted: PropTypes.bool,
};

export default TimelineItem;
