import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { mq } from '@utils/responsive';
import { Body as UnstyledBody } from '@components/type';

export const Wrapper = styled.div`
  width: 33.33%;
  color: ${colors.P500};
  background: #fff;
  overflow: hidden;
  text-align: center;
  z-index: 1;

  ${(props) =>
    props.active &&
    css`
      background: ${colors.P100};
    `}

  &:hover {
    cursor: pointer;
    background: ${colors.P100};
  }

  ${mq.gtmd`
    width: 31.5%;
    border: 2px solid ${colors.P100};
    border-radius: 8px;
  `}
`;

export const Info = styled.div`
  padding: 0.5rem;

  ${mq.gtmd`
    padding: 2rem;
  `}
`;

export const AssetWrapper = styled.div``;

export const Body = styled(UnstyledBody)`
  margin-top: 0.5rem;
  display: none;

  ${mq.gtsm`
    display: block;
  `}
`;
