import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import fonts from '@utils/fonts';
import { mq } from '@utils/responsive';
import { Body as UnstyledBody } from '@components/type';

export const Wrapper = styled.div`
  position: relative;
  padding: 3rem 0;

  ${mq.gtmd`
    display: flex;
    align-items: center;
    padding: 4rem 0;

    ${(props) =>
      props.inverted &&
      css`
        flex-direction: row-reverse;
      `}
  `}
`;

export const Info = styled.div`
  ${mq.gtmd`
    width: 41%;
  `}
`;

export const AssetWrapper = styled.div`
  margin-bottom: 2rem;

  ${mq.gtmd`
    width: 41%;
    margin-bottom: 0;

    ${(props) =>
      props.inverted &&
      css`
        margin-left: auto;
        margin-right: inherit;
      `}

    ${(props) =>
      !props.inverted &&
      css`
        margin-left: inherit;
        margin-right: auto;
      `}

    ${(props) =>
      props.tilted &&
      !props.inverted &&
      css`
        transform: rotate(-1deg);
      `}

    ${(props) =>
      props.tilted &&
      props.inverted &&
      css`
        transform: rotate(1deg);
      `}
  `}
`;

export const Body = styled(UnstyledBody)`
  margin-top: 1rem;
`;

export const Date = styled.div`
  color: ${(props) => getThemeStyles(props.colorTheme).text};
  text-align: center;
  position: relative;
  margin-bottom: 3rem;

  ${mq.gtmd`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const Time = styled.p`
  text-transform: uppercase;
`;

export const Backdrop = styled.div`
  position: absolute;
  width: 7.5rem;
  height: 7.5rem;
  left: 50%;
  top: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
    height: auto;
  }
`;

export const RelatedStory = styled.div`
  position: relative;
  padding: 48px 16px 32px;
  background-color: ${(props) => getThemeStyles(props.colorTheme).background};

  & > svg {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    transform: scale(-1, -1) translateY(1px);
    vertical-align: top;
  }

  & > *:first-child {
    position: absolute;
    z-index: 2;
    border: 0 !important;
    top: -1rem;
    left: 50%;
    transform: translateX(-50%);
  }

  ${mq.gtsm`
    padding: 64px 48px 48px;
  `}
`;

export const Quote = styled.div`
  color: ${props => getThemeStyles(props.colorTheme).text};
  font-family: ${props => fonts(props.theme.locale).HANDWRITTEN};
  font-size: 18px;
  line-height: 130%;

  ${mq.gtsm`
    margin-bottom: 64px;
    font-size: 30px;
    line-height: 140%;
  `}
`;

export function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        background: colors.B100,
        button: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        background: colors.G100,
        button: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        background: colors.O100,
        button: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        background: colors.P100,
        button: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        background: colors.Y100,
        button: colors.Y500,
        text: colors.B500,
      };
  }
}
